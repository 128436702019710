import React, { useEffect } from "react";

import { redirect } from "@app/components/Auth";
import { AppLogoIcon, BMessage } from "@app/ui";
import { PageProps } from "gatsby";
import { isBrowser } from "shared/utils";

export default function CallbackPage({ location }: PageProps) {
  useEffect(() => {
    if (isBrowser()) {
      const params = window.location.search;
      if (window.opener) {
        window.opener.postMessage(params, "*");
        window.close();
      } else {
        redirect({ params });
      }
    }
  }, []);

  return (
    <BMessage
      message={"redirect..."}
      icon={
        <>
          <AppLogoIcon boxSize={28} />
        </>
      }
    />
  );
}
