import React, { useEffect, useState } from "react";

import { navigateExt } from "@app/../../shared/utils";
import { useSession } from "@app/context/Session";
import {
  BButton,
  BMessage,
  EmailIcon,
  ErrorIcon,
  Link,
  LoadingBlock,
  RepeatIcon,
  Spacer,
  Text,
  useToast,
  VBox,
} from "@app/ui";
import { navigate } from "gatsby-link";

export interface CallbackProps {
  path: string;
  location: Location;
}

const ERRORS_MAP = {
  default: "An error has occured. Please, retry again or contact support",

  unauthorized: "Missed authorization, you can't perform this action",

  save_credentials_failed:
    "Failed to save credentials. Please retry again or contact support",

  invalid_redirect_url: "Invalid redirect URL",

  "#id003":
    "Notion workspace is already linked. Please use another one or a different Notion user account.",

  "#auth001":
    "Invalid verification link. Make sure to use the same device and browser from which you requested it.",
};

export const hasQueryError = ({ params }) => {
  params = new URLSearchParams(params);
  return params.has("error")
    ? ERRORS_MAP?.[params.get("error")] || ERRORS_MAP.default
    : null;
};
export const redirect = ({ params }) => {
  return navigateExt(
    `${process.env.GATSBY_APP_BASE_URL}/app/auth/redirect${params}`,
    { replace: false }
  );
};

export const Redirect = ({ path, location }: CallbackProps) => {
  const { login, switchTenant, startOnboarding } = useSession();

  const [error, setError] = useState(null);

  const { failure } = useToast();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // console.log("callback redirect debug params", location.search, params);

    if (params.has("error")) {
      const errMsg = ERRORS_MAP?.[params.get("error")] || ERRORS_MAP.default;
      setError(errMsg);
      failure(errMsg);
      return;
    }

    if (params.has("at")) {
      login(params.get("at"))
        .then(() => {
          params.has("p") && params.get("p")
            ? navigate(params.get("p"))
            : navigateExt(`${process.env.GATSBY_APP_BASE_URL}/app`, {
                replace: false,
              });
        })
        .catch((e) => {
          failure(ERRORS_MAP.default);
          console.error(e);
        });
    }

    if (params.has("tid")) {
      login()
        .then(() => switchTenant(params.get("tid").trim()))
        .then(() => {
          if (params.get("p").includes("onboarding?")) {
            startOnboarding().then(() => navigate(`/app/${params.get("p")}`));
            return;
          }
          navigate(`/app/${params.get("tid").trim()}/${params.get("p")}`);
        })
        .catch((err) => {
          console.error(err);
          failure("invalid workspace, or access denied");
          navigate("/app/select-workspace");
        });
    }
  }, []);

  return (
    <VBox maxW="500px" mx="auto">
      {error ? (
        <BMessage
          message={<Text fontSize={"lg"}>{error}</Text>}
          icon={
            <>
              {" "}
              <ErrorIcon mb={10} boxSize={32} />
            </>
          }
          action={
            <VBox>
              <Spacer h={24} />
              <BButton
                onClick={() => window.location.reload()}
                text={"Refresh page"}
                shortText={"Refresh page"}
                icon={<RepeatIcon boxSize={6} ml={2} />}
              />

              <Link
                mt={10}
                href={`mailto:support@decked.link?subject=${encodeURIComponent(
                  "About redirect to application issue"
                )}`}
              >
                Contact us
                <EmailIcon boxSize={6} ml={2} />
              </Link>
            </VBox>
          }
        />
      ) : (
        <BMessage
          message={"redirect..."}
          icon={
            <>
              <LoadingBlock />
            </>
          }
        />
      )}
    </VBox>
  );
};
